export const formTypes = {
    inputChangeFollowUp: "[NormalForm] InpuFollowUp changed",
    inputChange: "[NormalForm] Input changed",
    initForm: "[NormalForm] Init Form structure",
    addRepeaterRegister: "[NormalForm] Add register for repeater",
    removeRepeaterRegister: "[NormalForm] Remove register for repeater",
    editRepeaterRegister: "[NormalForm] Edit register for repeater",
    cleanForm: "[NormalForm] Clean Values",
    createFormFollowUp: "[NormalForm] Crate FollowUp",
    cleanFormFollowUp: "[NormalForm] Clean Values FollowUp",
    fillFormData: "[NormalForm] Fill form data",
    specificFormData: "[NormalForm] Specific form data",
    formchangeTab: "[NormalForm] Change Tab",
    deleteRegisterTab: "[NormalForm] Delete register tab",
    SwitchPermission: "[Permissions] Switch permission",
    SwitchPermissionAll: "[Permissions] Switch all permission",
    FillSelect: "[Permissions] Fill select",
};
