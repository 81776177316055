import { formTypes } from "../../types/normalForm/types";

// ** Initial State
const initialState = {};

const normalForm = (state = initialState, action) => {
    switch (action.type) {
        case formTypes.inputChange:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };

        case formTypes.inputChangeFollowUp:
            return {
                ...state,
                followUpInfo: [
                    {
                        ...state?.followUpInfo[0],
                        [action.payload.name]: action.payload.value,
                    },
                ],
            };

        case formTypes.initForm:
            return {
                ...action.payload,
            };

        case formTypes.addRepeaterRegister:
            return {
                ...state,
                [action.payload.key]: [...state[action.payload.key], { ...action.payload.structure }],
            };

        case formTypes.removeRepeaterRegister:
            const newState = state[action.payload.key].filter((element, index) => index !== action.payload.position);
            return {
                ...state,
                [action.payload.key]: [...newState],
            };

        case formTypes.editRepeaterRegister:
            const { key, position, obj } = action.payload;
            state[key][position] = {
                ...state[key][position],
                [obj.name]: obj.value,
            };
            return {
                ...state,
                [key]: [...state[key]],
            };

        case formTypes.cleanForm:
            return { ...initialState };

        case formTypes.cleanFormFollowUp:
            return {
                ...state,
            };
        case formTypes.createFormFollowUp:
            return {
                ...state,
                followUpInfo: [{}],
            };

        case formTypes.fillFormData:
            return {
                ...state,
                ...action.payload,
            };

        case formTypes.specificFormData:
            return {
                ...state,
                [action.payload.key]: action.payload.data,
            };

        case formTypes.SwitchPermission:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    [action.payload.permiso]: action.payload.value,
                },
            };
        case formTypes.SwitchPermissionAll:
            const newPermision = Object.entries(state[action.payload.key]).map((el) => {
                if (el[0] !== "blocks") {
                    return { id: [el[0]], value: action.payload.value };
                } else {
                    return { id: [el[0]], value: el[1] };
                }
            });

            const newP = newPermision.reduce((obj, item) => Object.assign(obj, { [item.id]: item.value }), {});

            return {
                ...state,
                [action.payload.key]: {
                    ...newP,
                },
            };
        case formTypes.FillSelect:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    select: action.payload.value,
                },
            };
        case formTypes.formchangeTab:
            const { index, name, valor, end, idEl } = action.payload;

            const newTab = {
                ...state[end].find((element) => {
                    if (element.id === idEl) {
                        return element;
                    }
                }),
                [name]: valor,
            };

            return {
                ...state,
                [end]: state?.[end].map((element) => (element.id === idEl ? newTab : element)),
            };
        case formTypes.deleteRegisterTab:
            const { id, endD } = action.payload;

            return {
                ...state,
                [endD]: state[endD].filter((reg) => reg.id !== id),
            };

        default:
            return state;
    }
};

export default normalForm;
