// ** React Imports
import { useState, createContext, useEffect } from "react";

// ** Intl Provider Import
import { IntlProvider } from "react-intl";
import { useHistory } from "react-router-dom";

// ** Core Language Data
import messagesEs from "@assets/data/locales/es.json";
import messagesEn from "@assets/data/locales/en.json";
import messagesFr from "@assets/data/locales/fr.json";
import messagesPt from "@assets/data/locales/pt.json";
import messagesEsCt from "@assets/data/locales/es-ct.json";

// ** User Language Data
import userMessagesEs from "@src/assets/data/locales/es.json";
import userMessagesEn from "@src/assets/data/locales/en.json";
import userMessagesFr from "@src/assets/data/locales/fr.json";
import userMessagesPt from "@src/assets/data/locales/pt.json";
import userMessagesEsCt from "@src/assets/data/locales/es-ct.json";

// ** Menu msg obj
const menuMessages = {
    es: { ...messagesEs, ...userMessagesEs },
    en: { ...messagesEn, ...userMessagesEn },
    fr: { ...messagesFr, ...userMessagesFr },
    pt: { ...messagesPt, ...userMessagesPt },
    "es-ct": { ...messagesEsCt, ...userMessagesEsCt },
    "es-mx": { ...messagesEs, ...userMessagesEs },
};

// ** Create Context
const Context = createContext();

const IntlProviderWrapper = ({ children }) => {
    const useData = JSON.parse(localStorage.getItem("userData"));
    // ** States
    const [locale, setLocale] = useState(useData?.language ? useData.language : "es");
    const [messages, setMessages] = useState(menuMessages[locale]);

    // ** Switches Language
    const switchLanguage = async (lang) => {
        setLocale(lang);
        setMessages(menuMessages[lang]);
    };

    return (
        <Context.Provider value={{ locale, switchLanguage }}>
            <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale="en">
                {children}
            </IntlProvider>
        </Context.Provider>
    );
};

export { IntlProviderWrapper, Context as IntlContext };
