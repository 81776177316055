import { formTypes } from "../../types/permisos/types";

// ** Initial State
const initialState = {
    contracts: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    customers: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    // customers: { read: true, insert: true, update: true, delete: true, actions: true, block: [{ id: 'organization', void: false, content: ['name', 'cif'] }] },
    forecasts: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    centers: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    closingStages: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    contractTypes: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    forecastType: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    charges: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    industries: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    legalTexts: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    taskTypes: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    reports: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    zones: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    leads: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    Invoice: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    tasks: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    // fees: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    talentoday: { read: true, block: [] },
    epreselec: { read: true, block: [] },
    infojobs: { read: true, block: [] },
    teamtailor: { read: true, block: [] },
    linkedin: { read: true, block: [] },
    queryLinkedin: { read: true, block: [] },
    invoice: { read: true, block: [] },
    intranet: { read: true, block: [] },
    candidateActivity: { read: true, insert: true, update: true, delete: true, actions: true, block: [] },
    analytics: { read: true, block: [] },
    users: { read: true, insert: true, update: true, delete: true, block: [] },
    roles: { read: true, insert: true, update: true, delete: true, block: [] },
    setup: { read: true, insert: true, update: true, delete: true, block: [] },
    PaymentMethods: { read: true, insert: true, update: true, delete: true, block: [] },
    country: { read: true, block: [] },
    addressTypes: { read: true, insert: true, update: true, delete: true, block: [] },
    Alerts: { read: true, delete: true, block: [] },
    Logs: { read: true, block: [] },
};

const permisosReducer = (state = initialState, action) => {
    switch (action.type) {
        case formTypes.SwitchPermission:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    [action.payload.permiso]: action.payload.value,
                },
            };
        case formTypes.SwitchPermissionAll:
            console.log(state.payload);
            const newPermision = Object.entries(state[action.payload.key]).map((el) => {
                return { id: [el[0]], value: action.payload.value };
            });

            const newP = newPermision.reduce((obj, item) => Object.assign(obj, { [item.id]: item.value }), {});

            return {
                ...state,
                [action.payload.key]: {
                    ...newP,
                },
            };
        case formTypes.FillPermision:
            return {
                ...action.payload,
            };
        case formTypes.CleanPermisions:
            return { ...initialState };
        default:
            return state;
    }
};

export default permisosReducer;
